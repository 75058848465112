import {
  Alert,
  ArrowRightIcon,
  Box,
  CBButton,
  CancelFilledIcon,
  CancelIcon,
  CannotActivateCardIcon,
  CardIssuedIcon,
  CheckCircleSolidIcon,
  CheckIcon,
  Circle,
  CopyIcon,
  CopyToClipboard,
  FormField,
  GradientCardIcon,
  InformationCircleIcon,
  Inline,
  Modal,
  ModalBody,
  ModalFooter,
  PhoneInput,
  PlusIcon,
  SearchIcon,
  SpinnerIcon,
  Stack,
  Text,
  UserAddIcon,
  WhatsAppIcon,
  formikOnSubmitWithErrorHandling,
  useOverlayTriggerState,
} from "@cashbook/web-components"
import { useUser } from "reactfire"
import { FieldProps, Form, Formik, useField } from "formik"
import { toast } from "react-hot-toast"
import {
  WalletEnabledResponse,
  getSubscriptionWalletCount,
  useCheckIfPaymentsEnabled,
} from "@cashbook/data-store/payments"
import config from "../config"
import { TrackingEvents, trackEvent } from "@cashbook/util-tracking"
import { useMemo, useRef, useState, useSyncExternalStore } from "react"
import {
  getAllBusinessRolesWithPermissions,
  getBusinessRoleDetails,
  T_AVAILABLE_BUSINESS_ROLES,
  TBusiness,
  TBusinessMember,
  useAddTeamMember,
  useBusiness,
  useBusinesses,
  useShareBusinessInvitations,
} from "@cashbook/data-store/businesses"
import { Avatar } from "."
import { parsePhoneNumber } from "react-phone-number-input"
import * as Validator from "yup"
import {
  PhoneNumberValidator,
  arePhoneNumbersSame,
  isPhoneNumberIndian,
} from "@cashbook/util-general"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { useCheckUsersExists } from "@cashbook/data-store/users"
import { WhatsAppContactLink } from "../HelpDocs"
import { businessRolesColorCodes } from "../Business"
import { NotInWeb } from "../support"
import { showPaymentsStore } from "@cashbook/data-store/storage"

type PurchaseWalletStatusProps = {
  isLoading: boolean
  error: Error | null
  purchasedCount: number
  onRetry: () => void
  onPurchase: () => void
  isOwner: boolean
}

export function PurchaseWalletStatus({
  isLoading,
  error,
  purchasedCount,
  onRetry,
  onPurchase,
  isOwner,
}: PurchaseWalletStatusProps) {
  return (
    <Stack gap="6" alignItems="center">
      {isLoading ? (
        <>
          <SpinnerIcon size="12" />
          <Text fontSize="s1">Checking eligibility...</Text>
        </>
      ) : error ? (
        <>
          <Box>
            <CancelFilledIcon size="24" color="iconError" />
          </Box>
          <Stack gap="6" alignItems="center">
            <Text fontSize="s1" color="textError">
              {error.message || "Failed to check wallet status"}
            </Text>
            <CBButton level="primary" size="lg" onClick={onRetry}>
              Retry
            </CBButton>
          </Stack>
        </>
      ) : (
        <>
          <Box padding="8">
            <CannotActivateCardIcon size="24" />
          </Box>
          <Stack gap="6" alignItems="center">
            <Text fontSize="s1">
              {purchasedCount === 0
                ? "There are no wallets purchased for this business"
                : `There are only ${purchasedCount} wallets purchased for this business`}
            </Text>
            <Text fontSize="c1" color="textMedium">
              {isOwner
                ? "Purchase more wallets to issue to your team members"
                : "Ask your owner to purchase wallets for you"}
            </Text>
            {isOwner && (
              <CBButton
                level="primary"
                status="success"
                size="lg"
                onClick={onPurchase}
              >
                Purchase Wallets
              </CBButton>
            )}
          </Stack>
        </>
      )}
    </Stack>
  )
}

type SubscriptionWalletsCount = {
  purchased: number
  activated: {
    subscribed: number
    unsubscribed: number
    deactivating: number
  }
  issued: {
    users: number
    invitations: number
  }
  unutilized: number
}

type IssueWalletSteps =
  | "choose_member"
  | "issue_wallet"
  | "wallet_issued"
  | "check_user_status"
  | "purchase_wallet"

type SelectedMemberForWallet = {
  name: string
  phone: string
  id: string
  isExistingUser?: boolean
  isBusinessUser?: boolean
  inviteLink?: string
}

type PropsForStepChange = {
  step: IssueWalletSteps
  member?: SelectedMemberForWallet
  inviteLink?: string
}

const useWalletIssueGuard = (businessId: string) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  const checkWalletIssueEligibility = async () => {
    try {
      setLoading(true)
      const subscriptionWalletsCount =
        await getSubscriptionWalletCount<SubscriptionWalletsCount>({
          businessId,
        })
      return {
        canIssueWallet: subscriptionWalletsCount.unutilized > 0,
        purchased: subscriptionWalletsCount.purchased,
      }
    } catch (error) {
      setError(
        error instanceof Error
          ? error
          : new Error("Failed to fetch wallet count")
      )
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return {
    checkWalletIssueEligibility,
    loading,
    error,
  }
}

export function IssueWalletInModal({
  children,
  ...props
}: CommonPropsForIssueWallet & {
  children: (props: { open: () => void }) => React.ReactNode
}) {
  const state = useOverlayTriggerState({})
  const walletExistState = useOverlayTriggerState({})
  const { checkIfPaymentsEnabled } = useCheckIfPaymentsEnabled(props.businessId)
  const { business, authTeamMemberDetails, businessTeam } = useBusiness(
    props.businessId
  )

  const { getAuthMemberRoleForBusinessId } = useBusinesses()

  const isOwner =
    getAuthMemberRoleForBusinessId(props.businessId)?.id === "owner"

  const showPayments = useSyncExternalStore(
    showPaymentsStore.subscribe,
    showPaymentsStore.getShowPaymentsList
  )

  const isSubscriptionInactive =
    showPayments[props.businessId]?.subscription?.status === "INACTIVE"
  const {
    checkWalletIssueEligibility,
    loading: walletCheckLoading,
    error: walletCheckError,
  } = useWalletIssueGuard(props.businessId)

  const [step, setStep] = useState<{
    prevStep?: IssueWalletSteps
    current: IssueWalletSteps
  }>({ current: "purchase_wallet" })

  const [selectedMember, setSelectedMember] =
    useState<SelectedMemberForWallet | null>(null)
  const purchasedCountRef = useRef<number>(0)

  async function onOpen() {
    state.open()
    if (isSubscriptionInactive) {
      setStep({ current: "purchase_wallet" })
      return
    }
    const response = await checkWalletIssueEligibility()
    purchasedCountRef.current = response?.purchased || 0
    if (response?.canIssueWallet) {
      setStep({ current: "choose_member" })
    }
  }

  function onStepChange({ step, member, inviteLink }: PropsForStepChange) {
    if (step === "choose_member") {
      setStep({ current: step })
      setSelectedMember(null)
      return
    }

    if (inviteLink?.length) {
      setSelectedMember((prev) => {
        if (prev) {
          return { ...prev, inviteLink }
        }
        return null
      })
    }
    if (member?.id) {
      setSelectedMember(member)
    }
    setStep((prev) => {
      return {
        prevStep: prev.current,
        current: step,
      }
    })
  }

  function onBackHandler() {
    setStep((prev) => {
      return {
        current: prev?.prevStep || "choose_member",
        prevStep: "choose_member",
      }
    })
  }

  const selectedUserRole = useMemo(() => {
    if (selectedMember?.id && selectedMember?.id !== selectedMember?.phone) {
      const found = businessTeam.find(({ id }) => selectedMember.id === id)
      if (found && found.role.id !== "owner") {
        return found.role.id
      }
      return undefined
    }
  }, [businessTeam, selectedMember?.id, selectedMember?.phone])

  const onClose = () => {
    state.close()
    setStep({ current: "purchase_wallet" })
    setSelectedMember(null)
  }

  return (
    <>
      {children({
        open: onOpen,
      })}
      <Modal
        title={
          step.current === "choose_member"
            ? "Issue Member Wallets"
            : step.current === "issue_wallet"
            ? "Issue Wallet"
            : step.current === "wallet_issued"
            ? "Share Wallet Invite"
            : step.current === "check_user_status"
            ? "Issue New Wallet"
            : step.current === "purchase_wallet"
            ? "Purchase Wallets"
            : ""
        }
        placement="right"
        isOpen={state.isOpen}
        onClose={onClose}
        onBackPress={
          step.current !== "choose_member" &&
          step.current !== "wallet_issued" &&
          step.current !== "purchase_wallet"
            ? onBackHandler
            : undefined
        }
      >
        {step.current === "purchase_wallet" ? (
          <NotInWeb
            heading={
              isSubscriptionInactive
                ? "Your subscription is inactive"
                : "Purchase wallets on mobile app"
            }
          >
            {({ onDisplay }) => (
              <ModalBody>
                {isSubscriptionInactive ? (
                  <Stack gap="6" alignItems="center" padding="8">
                    <Text fontSize="c2" color="textMedium">
                      {isOwner
                        ? "Your subscription is inactive. Please renew the business subscription to issue wallets"
                        : "Your subscription is inactive. Please ask your owner to renew the subscription to issue wallets"}
                    </Text>
                    {isOwner && (
                      <CBButton level="primary" size="lg" onClick={onDisplay}>
                        Renew Subscription
                      </CBButton>
                    )}
                  </Stack>
                ) : (
                  <PurchaseWalletStatus
                    isLoading={walletCheckLoading}
                    error={walletCheckError}
                    purchasedCount={purchasedCountRef.current}
                    onRetry={onOpen}
                    onPurchase={onDisplay}
                    isOwner={isOwner}
                  />
                )}
              </ModalBody>
            )}
          </NotInWeb>
        ) : step.current === "choose_member" ? (
          <ChooseMember
            {...props}
            authTeamMemberDetails={authTeamMemberDetails}
            businessTeam={businessTeam}
            onStepChange={onStepChange}
            checkIfPaymentsEnabled={checkIfPaymentsEnabled}
            enableWalletExistModal={() => walletExistState.open()}
          />
        ) : step.current === "check_user_status" ? (
          <CheckIfUsersExists
            {...props}
            onStepChange={onStepChange}
            checkIfPaymentsEnabled={checkIfPaymentsEnabled}
            enableWalletExistModal={() => walletExistState.open()}
          />
        ) : step.current === "issue_wallet" && selectedMember?.id ? (
          <IssueWallet
            {...props}
            business={business}
            member={selectedMember}
            onStepChange={onStepChange}
            selectedUserRole={selectedUserRole}
          />
        ) : selectedMember ? (
          <ShareWalletInvite
            member={selectedMember}
            businessName={business.name}
            onStepChange={onStepChange}
          />
        ) : null}
      </Modal>
      <Modal
        title="Unable to Issue Wallet"
        isOpen={walletExistState.isOpen}
        onClose={walletExistState.close}
      >
        <ModalBody>
          <Stack gap="6" textAlign="center" alignItems="center">
            <CannotActivateCardIcon size="24" />
            <Stack gap="6" alignItems="center">
              <Text fontSize="s1">
                Multiple Wallets can't exist with same phone number
              </Text>
              <Box
                backgroundColor="gray400"
                rounded="full"
                width="6"
                height="2"
              />
              <Stack as="ul" gap="4">
                {pointers_for_multiple_wallets.map((pointer) => (
                  <Inline id={pointer} alignItems="center" gap="3">
                    <Circle size="2" backgroundColor="gray400" />
                    <Text>{pointer}</Text>
                  </Inline>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <CBButton level="primary" size="lg" onClick={walletExistState.close}>
            <CheckIcon />
            Okay, Got It
          </CBButton>
        </ModalFooter>
      </Modal>
    </>
  )
}

function sortContacts(a: TBusinessMember, b: TBusinessMember) {
  const contact1 = a?.name?.toUpperCase()
  const contact2 = b?.name?.toUpperCase()
  return contact1 < contact2 ? -1 : contact1 > contact2 ? 1 : 0
}

type CommonPropsForIssueWallet = {
  businessId: string
  onStepChange?: (props: PropsForStepChange) => void
}
function ChooseMember({
  businessTeam,
  authTeamMemberDetails,
  onStepChange,
  checkIfPaymentsEnabled,
  enableWalletExistModal,
}: CommonPropsForIssueWallet & {
  businessTeam: TBusinessMember[]
  authTeamMemberDetails: TBusinessMember
  enableWalletExistModal?: () => void
  checkIfPaymentsEnabled: (
    memberId?: string | undefined
  ) => Promise<WalletEnabledResponse>
}) {
  const [q, setQuery] = useState<string>("")

  const issuedMembers = useMemo(
    () =>
      businessTeam
        .filter((user) => !!user.can_enable_wallet)
        .sort(sortContacts),
    [businessTeam]
  )

  const nonIssuedMembers = useMemo(
    () =>
      businessTeam.filter((user) => !user.can_enable_wallet).sort(sortContacts),
    [businessTeam]
  )

  const businessMembers = useMemo(
    () =>
      [...nonIssuedMembers, ...issuedMembers].filter(
        (user) =>
          user.phoneNumber?.includes(q) ||
          user.name.toLowerCase().includes(q.toLowerCase())
      ),
    [issuedMembers, nonIssuedMembers, q]
  )

  return (
    <>
      <ModalBody>
        <Stack gap="6">
          <Inline
            position="relative"
            rounded="md"
            height="10"
            paddingRight="2"
            alignItems="stretch"
            gap="6"
            borderWidth="1"
            backgroundColor="backgroundLight1"
            width="full"
            borderColor="borderOutline"
            className="bg-opacity-20 focus-within:border-blue-900 focus-within:ring-1 ring-blue-900"
          >
            <input
              type="search"
              name="q"
              autoComplete="off"
              placeholder="Search by name or number"
              value={q}
              onChange={(e) => setQuery(e.currentTarget.value)}
              className="bg-transparent outline-none flex-1 pl-4 placeholder:gray-500"
            />
            <Inline
              as="button"
              type="button"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                if (q) setQuery("")
              }}
            >
              {q.length ? (
                <CancelIcon color="gray900" />
              ) : (
                <SearchIcon color="gray500" />
              )}
            </Inline>
          </Inline>
          <Inline
            as="button"
            rounded="md"
            borderWidth="1"
            padding="4"
            gap="4"
            alignItems="center"
            justifyContent="between"
            onClick={() => onStepChange?.({ step: "check_user_status" })}
          >
            <Inline gap="4">
              <Circle size="12">
                <UserAddIcon />
              </Circle>
              <Stack gap="2" textAlign="left">
                <Text fontSize="s3">Add New Member</Text>
                <Text fontSize="c2" color="textMedium">
                  Add member to team & issue wallet
                </Text>
              </Stack>
            </Inline>
            <ArrowRightIcon />
          </Inline>
          <Stack gap="4">
            <Text fontSize="s4" color="textMedium">
              Issue wallet to existing members
            </Text>
            <Stack as="ul">
              {businessMembers.length
                ? businessMembers.map((member) => {
                    const isWalletIssued = member.can_enable_wallet
                    return (
                      <Inline
                        as="li"
                        key={member.id}
                        paddingY="4"
                        borderBottomWidth="1"
                        borderColor="borderDividers"
                        alignItems="center"
                      >
                        <Inline
                          as="button"
                          width="full"
                          alignItems="center"
                          disabled={isWalletIssued}
                          justifyContent="between"
                          onClick={async () => {
                            if (!isPhoneNumberIndian(member.phoneNumber)) {
                              return toast.error(
                                "You can only issue wallet to Indian phone number!"
                              )
                            }
                            const response = await checkIfPaymentsEnabled(
                              member.uid
                            )
                            if (response.data?.walletEnabled) {
                              return enableWalletExistModal?.()
                            }
                            const selectedMember = {
                              id: member.id,
                              phone: member?.phoneNumber || "",
                              name: member.name,
                              isExistingUser: true,
                              isBusinessUser: true,
                            }
                            onStepChange?.({
                              step: "issue_wallet",
                              member: selectedMember,
                            })
                          }}
                        >
                          <Inline gap="6">
                            <Avatar
                              size="12"
                              id={member.id}
                              name={member.name}
                            />
                            <Stack gap="2" textAlign="left">
                              <Text fontSize="s3">{member.name}</Text>
                              <Text fontSize="b3" color="textMedium">
                                {member?.phoneNumber || member?.email || ""}
                              </Text>
                            </Stack>
                          </Inline>
                          <Inline gap="2" alignItems="center">
                            {isWalletIssued ? (
                              <CheckCircleSolidIcon color="iconSuccess" />
                            ) : null}
                            <Text
                              fontSize="bt"
                              color={
                                isWalletIssued ? "textMedium" : "textPrimary"
                              }
                            >
                              {isWalletIssued ? "ISSUED" : "ISSUE"}
                            </Text>
                          </Inline>
                        </Inline>
                      </Inline>
                    )
                  })
                : null}
            </Stack>
          </Stack>
        </Stack>
      </ModalBody>
    </>
  )
}

const pointers_for_multiple_wallets = [
  "Another wallet already exists for this number in some other business",
  "Try another number",
]
function CheckIfUsersExists({
  businessId,
  onStepChange,
  checkIfPaymentsEnabled,
  enableWalletExistModal,
}: CommonPropsForIssueWallet & {
  enableWalletExistModal?: () => void
  checkIfPaymentsEnabled: (
    memberId?: string | undefined
  ) => Promise<WalletEnabledResponse>
}) {
  const { data: user } = useUser()
  const { checkIfPhonesExists } = useCheckUsersExists()
  const { businessTeam } = useBusiness(businessId)
  const { invitations } = useShareBusinessInvitations(businessId)

  const { phoneNumber: myPhoneNumber } = user || { phoneNumber: "" }
  const defaultCountry = useMemo(
    () =>
      (myPhoneNumber ? parsePhoneNumber(myPhoneNumber)?.country : "IN") || "IN",
    [myPhoneNumber]
  )

  async function checkUserStatus(phone: string) {
    try {
      const { data: usersInfo } = await checkIfPhonesExists([phone])
      if (usersInfo[0]?.isAppUser && usersInfo[0]?.uid) {
        const response = await checkIfPaymentsEnabled(usersInfo[0].uid)
        if (response.data?.walletEnabled) {
          return enableWalletExistModal?.()
        }
      }
      if (usersInfo[0]) {
        const selectedMember = {
          name:
            usersInfo[0]?.name ||
            parsePhoneNumber(usersInfo[0].phone)?.nationalNumber ||
            "CashBook User",
          phone: usersInfo[0]?.phone || "",
          id: usersInfo[0]?.uid || usersInfo[0].phone,
          isExistingUser: usersInfo[0]?.isAppUser || false,
        }
        onStepChange?.({ step: "issue_wallet", member: selectedMember })
      }
    } catch (e) {
      const error = e as Error
      throw error
    }
  }

  return (
    <>
      <Formik
        initialValues={{ phoneNumber: "" as string }}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Validator.object().shape({
          phoneNumber: PhoneNumberValidator.test({
            name: "valid-phone-number",
            test: (value: string | undefined | null) => {
              if (!value) {
                return false
              }
              if (!isPhoneNumberIndian(value)) {
                return false
              }
              return (
                isPossiblePhoneNumber(value) &&
                !businessTeam.some((m) =>
                  arePhoneNumbersSame(m.phoneNumber, value)
                ) &&
                !invitations.some((m) =>
                  arePhoneNumbersSame(m.guestPhone, value)
                )
              )
            },
            message: function test(params) {
              if (!params.value) {
                return "Please use valid mobile number"
              }
              if (!isPhoneNumberIndian(params.value)) {
                return "You can only issue wallet to Indian phone number!"
              }
              const existingMember = businessTeam.find((m) =>
                arePhoneNumbersSame(m.phoneNumber, params.value)
              )
              const existingInvitation = invitations.find((m) =>
                arePhoneNumbersSame(m.guestPhone, params.value)
              )
              if (existingMember?.uid === user?.uid) {
                return `You are not allowed to add your number to the business`
              }
              if (existingInvitation) {
                return `Member with this phone (${existingInvitation?.guestPhone}) already invited in the business.`
              }
              if (existingMember) {
                return `Member with this phone (${existingMember?.phoneNumber}) already added to this business.`
              }
              return `Please enter a valid mobile number`
            },
          }),
        })}
        onSubmit={formikOnSubmitWithErrorHandling(async (values) => {
          await checkUserStatus(values.phoneNumber)
        })}
      >
        {({ isSubmitting, status }) => (
          <Form noValidate>
            <ModalBody>
              <Stack gap="6">
                <Stack gap="1">
                  <Box as="label" htmlFor="phoneNumber" fontSize="c2">
                    Mobile number{" "}
                    <Text as="span" fontSize="s3" color="iconError">
                      *
                    </Text>
                  </Box>
                  <FormField
                    name="phoneNumber"
                    renderInput={({
                      field: { onChange, ...otherFieldProps },
                      form,
                    }: FieldProps<string>) => (
                      <Box className="max-w-xs">
                        <PhoneInput
                          {...otherFieldProps}
                          id="phoneNumber"
                          onChange={(phoneNumber) =>
                            form.setFieldValue(
                              otherFieldProps.name,
                              phoneNumber
                            )
                          }
                          defaultCountry={defaultCountry}
                          required
                          autoFocus
                        />
                      </Box>
                    )}
                  />
                </Stack>
                {status ? <Alert status="error">{status}</Alert> : null}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CBButton
                type="submit"
                level="primary"
                size="lg"
                loading={isSubmitting}
              >
                {isSubmitting ? "Verifying Number..." : "Next"}
              </CBButton>
              <CBButton size="lg" disabled={isSubmitting}>
                Cancel
              </CBButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

function SelectFromBusinessRoles({ fieldName }: { fieldName: string }) {
  const roles = getAllBusinessRolesWithPermissions().filter(
    (role) => role.id !== "owner"
  )

  const [{ value }] = useField<T_AVAILABLE_BUSINESS_ROLES>(fieldName)

  const { permissionsDescription, restrictionsDescription } =
    getBusinessRoleDetails(value, true)

  return (
    <Stack gap="4">
      <Box borderWidth="1" borderColor="borderOutline" rounded="md">
        <Box
          borderBottomWidth="1"
          borderColor="borderOutline"
          paddingX="6"
          paddingY="5"
        >
          <Text fontSize="s3">Choose Role</Text>
        </Box>
        <Box paddingX="6" paddingY="5">
          <Inline as="ul" gap="4">
            {roles.map((role) => (
              <FormField
                key={role.id}
                label={
                  <Box
                    as="li"
                    backgroundColor={
                      value === role.id
                        ? businessRolesColorCodes[role.id].backgroundColor
                        : "surfaceNeutralLowest"
                    }
                    key={role.id}
                    rounded="full"
                    display="inlineBlock"
                    paddingX="4"
                    paddingY="2"
                    borderWidth="1"
                    color={
                      value === role.id
                        ? businessRolesColorCodes[role.id].color
                        : "textMedium"
                    }
                    borderColor={
                      value === role.id
                        ? businessRolesColorCodes[role.id].borderColor
                        : "borderOutline"
                    }
                    cursor="pointer"
                  >
                    <Text as="span" fontWeight="medium" fontSize="base">
                      {role.title}
                    </Text>
                  </Box>
                }
                type="radio"
                name={fieldName}
                value={role.id}
                id={`${fieldName}.${role.id}`}
                noMargin
                invisibleInput
                hideError
              />
            ))}
          </Inline>
          <Box paddingTop="8">
            <Stack gap="6">
              {permissionsDescription.length ? (
                <Stack gap="4">
                  <Text color="textMedium" fontSize="s4">
                    Permissions
                  </Text>
                  <Stack as="ul" gap="4">
                    {permissionsDescription.map((permission) => (
                      <Inline
                        as="li"
                        key={permission}
                        gap="3"
                        alignItems="center"
                      >
                        <Box>
                          <CheckCircleSolidIcon color="iconSuccess" />
                        </Box>
                        <Text fontSize="b3">{permission}</Text>
                      </Inline>
                    ))}
                  </Stack>
                </Stack>
              ) : null}
              {restrictionsDescription.length ? (
                <Stack gap="4">
                  <Text color="textMedium" fontSize="s4">
                    Restrictions
                  </Text>
                  <Stack as="ul" gap="4">
                    {restrictionsDescription.map((restriction) => (
                      <Inline
                        as="li"
                        key={restriction}
                        gap="3"
                        alignItems="center"
                      >
                        <Box>
                          <CancelFilledIcon color="iconError" />
                        </Box>
                        <Text fontSize="b3">{restriction}</Text>
                      </Inline>
                    ))}
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Inline alignItems="center" color="textMedium" gap="3">
        <InformationCircleIcon size="5" color="iconMedium" />
        <Text fontSize="c2">You can change this role later</Text>
      </Inline>
    </Stack>
  )
}

const SHARE_WALLET_INVITE_LINK = "https://cbook.page.link/activateWallet"

function IssueWallet({
  member,
  business,
  selectedUserRole,
  onStepChange,
}: CommonPropsForIssueWallet & {
  business: TBusiness
  member: SelectedMemberForWallet
  selectedUserRole?: "staff" | "partner"
}) {
  const addToBusiness = useAddTeamMember()
  const { data: currentUser } = useUser()
  const isIssuingToSelf = useMemo(() => {
    return currentUser?.uid === member.id
  }, [member.id, currentUser?.uid])

  const memberExistence = useMemo(() => {
    if (member.isBusinessUser) {
      return "businessUser"
    } else if (member.isExistingUser) {
      return "cashbookUser"
    } else {
      return "newUser"
    }
  }, [member])

  return (
    <Formik
      initialValues={{
        addingToBusiness: false as boolean,
        issuingWallet: false as boolean,
        role: selectedUserRole || ("staff" as string),
      }}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={formikOnSubmitWithErrorHandling(async (values, actions) => {
        actions.setFieldValue("issuingWallet", true)
        if (memberExistence !== "businessUser") {
          actions.setFieldValue("addingToBusiness", true)
        }
        const { data } = await addToBusiness({
          businessId: business.id,
          guestName: member.name,
          guestPhone: member.phone,
          role: values.role,
          can_enable_wallet: true,
        })
        trackEvent(TrackingEvents.WALLET_ISSUED, {
          memberType: memberExistence,
        })
        actions.setFieldValue("addingToBusiness", false)
        setTimeout(() => {
          actions.setFieldValue("issuingWallet", false)
          onStepChange?.({
            step: "wallet_issued",
            inviteLink: data.inviteLink || SHARE_WALLET_INVITE_LINK,
          })
        }, 3000)
      })}
    >
      {({ values, setFieldValue }) => {
        return values.addingToBusiness || values.issuingWallet ? (
          <ModalBody>
            <Stack
              height="full"
              width="full"
              alignItems="center"
              justifyContent="center"
            >
              {values.addingToBusiness ? (
                <Stack gap="4">
                  <Inline
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SpinnerIcon size="16" className="text-[#5A72D8]" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="absolute"
                    >
                      <path
                        d="M13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12C11.21 12 13 10.21 13 8ZM15 10V12H18V15H20V12H23V10H20V7H18V10H15ZM1 18V20H17V18C17 15.34 11.67 14 9 14C6.33 14 1 15.34 1 18Z"
                        fill="#578BFF"
                      />
                    </svg>
                  </Inline>
                  <Stack gap="3" textAlign="center">
                    <Text fontSize="s3" color="textPrimary">
                      Adding {member.name} to Team
                    </Text>
                    <Text fontSize="b3">
                      Role:{" "}
                      <Text as="span" textTransform="capitalize">
                        {values.role}
                      </Text>
                    </Text>
                  </Stack>
                </Stack>
              ) : (
                <Stack gap="4">
                  <Inline
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <SpinnerIcon size="16" className="text-[#5A72D8]" />
                    <GradientCardIcon className="absolute" />
                  </Inline>
                  <Stack gap="3" textAlign="center">
                    <Text fontSize="s3" color="textPrimary">
                      Creating wallet for{" "}
                      {isIssuingToSelf ? "You" : member.name}
                    </Text>
                    <Text fontSize="b3">Please wait...</Text>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </ModalBody>
        ) : (
          <Form noValidate>
            <ModalBody>
              {memberExistence !== "businessUser" ? (
                <Box marginBottom="4">
                  <Text fontSize="b3">
                    {memberExistence === "cashbookUser"
                      ? `${member.name} is already using ${config.appTitle}
                          app. Add user to this business after choosing role and issue wallet`
                      : `${member?.phone} is a new user. Send invite to ${member?.phone} to join this business after issuing wallet`}
                  </Text>
                </Box>
              ) : (
                <Box marginBottom="4">
                  <Text fontSize="b3">
                    Issuing wallet to existing Business Member
                  </Text>
                </Box>
              )}
              <Stack gap="10">
                <Inline
                  borderWidth="1"
                  borderColor="borderOutline"
                  paddingY="4"
                  paddingX="6"
                  rounded="md"
                  gap="6"
                  alignItems="center"
                >
                  <Avatar size="12" name={member.name} id={member.id} />
                  <Stack flex="1" gap="2">
                    <Text fontSize="s3">
                      {isIssuingToSelf ? "You" : member.name}
                    </Text>
                    <Text fontSize="b3" color="textMedium">
                      {member.phone}
                    </Text>
                  </Stack>
                  {memberExistence !== "businessUser" ? (
                    <Box
                      bgColor={
                        memberExistence === "cashbookUser"
                          ? "surfacePrimaryLowest"
                          : "surfaceNeutralLowest"
                      }
                      rounded="md"
                      paddingX="3"
                      paddingY="1"
                    >
                      <Text
                        color={
                          memberExistence === "cashbookUser"
                            ? "textPrimary"
                            : "textMedium"
                        }
                        fontSize="c2"
                      >
                        {memberExistence === "cashbookUser" ? "" : "Not a"}{" "}
                        {config.appTitle} User
                      </Text>
                    </Box>
                  ) : (
                    <Box
                      bgColor={
                        selectedUserRole === "staff"
                          ? "surfacePrimaryLowest"
                          : "surfaceWarningLowest"
                      }
                      rounded="md"
                      paddingX="3"
                      paddingY="1"
                    >
                      <Text
                        color={
                          selectedUserRole === "staff"
                            ? "textPrimary"
                            : "textWarning"
                        }
                        fontSize="c2"
                      >
                        {selectedUserRole === "staff" ? "Staff" : "Partner"}
                      </Text>
                    </Box>
                  )}
                </Inline>
                {memberExistence !== "businessUser" && (
                  <Box width="full">
                    <SelectFromBusinessRoles fieldName="role" />
                  </Box>
                )}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <CBButton iconPlacement="right" size="lg" type="submit">
                Issue Wallet <ArrowRightIcon />{" "}
              </CBButton>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}

function ShareWalletInvite({
  member,
  businessName,
  onStepChange,
}: Pick<CommonPropsForIssueWallet, "onStepChange"> & {
  businessName?: string
  member: SelectedMemberForWallet
}) {
  const { data: currentUser } = useUser()
  const isIssuingToSelf = useMemo(() => {
    return currentUser?.uid === member.id
  }, [currentUser?.uid, member.id])

  const shareInviteText = useMemo(() => {
    return `Hey ${member.name}, I have created wallet for you to make business expense for ${businessName}. Here is the link to activate wallet - ${member.inviteLink}`
  }, [businessName, member.name, member.inviteLink])

  return (
    <>
      <ModalBody>
        <Stack gap="6" alignItems="center">
          <Stack gap="3" alignItems="center">
            <CardIssuedIcon size="16" />
            <Text fontSize="s1">Wallet Issued!</Text>
          </Stack>
          <Inline
            gap="6"
            paddingY="4"
            paddingX="6"
            borderWidth="1"
            rounded="md"
            width="full"
            borderColor="borderDividers"
          >
            <Avatar id={member.id} name={member.name} size="12" />
            <Stack gap="4">
              <Stack gap="2">
                <Text fontSize="s3">{member.name}</Text>
                <Text fontSize="b3" color="textMedium">
                  {member.isExistingUser
                    ? member.phone
                    : `Not a ${config.appTitle} user`}
                </Text>
              </Stack>
            </Stack>
          </Inline>
          {!isIssuingToSelf ? (
            <Box>
              <Inline
                backgroundColor="surfaceNeutralLowest"
                paddingY="2"
                paddingX="4"
                roundedTop="md"
              >
                <Text fontSize="c2" color="textMedium">
                  Share this message with {member.name}
                </Text>
              </Inline>
              <Stack
                gap="3"
                paddingY="5"
                paddingX="4"
                borderXWidth="1"
                roundedBottom="md"
                borderBottomWidth="1"
                borderColor="borderDividers"
              >
                <Text fontSize="s3">
                  <Text
                    as="span"
                    aria-labelledby="hello-emoji"
                    aria-label="hello emoji"
                    role="img"
                  >
                    {" "}
                    👋🏼{" "}
                  </Text>
                  Hey {member.name},
                </Text>
                <Text fontSize="b3" color="textMedium">
                  I have created wallet for you to make business expense for
                  {businessName}. Here is the link to activate wallet -{" "}
                  {member.inviteLink}
                </Text>
              </Stack>
            </Box>
          ) : (
            <NotInWeb heading="Use mobile app to activate your wallet">
              {({ onDisplay }) => (
                <Stack gap="4" width="full">
                  <CBButton size="lg" level="primary" onClick={onDisplay}>
                    Activate Wallet
                  </CBButton>
                </Stack>
              )}
            </NotInWeb>
          )}

          <Inline
            gap="2"
            paddingY="3"
            rounded="md"
            backgroundColor="surfaceAlt2Lowest"
            color="textPrimary"
            alignItems="center"
            width="full"
            justifyContent="center"
            as="button"
            onClick={() => {
              onStepChange?.({ step: "choose_member" })
            }}
          >
            <PlusIcon />
            <Text>Issue More Wallets</Text>
          </Inline>
        </Stack>
      </ModalBody>
      {!isIssuingToSelf && (
        <ModalFooter>
          <CopyToClipboard>
            {({ copy, copied }) => (
              <CBButton
                size="lg"
                level="primary"
                disabled={copied}
                onClick={() => {
                  copy(shareInviteText)
                  toast.success("Copied invite link successfully!")
                }}
              >
                {copied ? <CheckIcon /> : <CopyIcon />} Copy Link
              </CBButton>
            )}
          </CopyToClipboard>
          <WhatsAppContactLink
            phoneNumber={member.phone}
            size="lg"
            text={shareInviteText}
          >
            <WhatsAppIcon />
            Share on WhatsApp
          </WhatsAppContactLink>
        </ModalFooter>
      )}
    </>
  )
}
